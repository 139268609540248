.contact_sec textarea, input[type="text"], input[type="file"] {
    width: 100%;
    max-width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: none;
    border-radius: 6px;
    /* background: #3d2f2a;  */
    /* color: #d9c4a0; */
    /* font-family: 'Mate SC', serif; */
    /* box-shadow: inset 4px 4px 8px #29221d, inset -4px -4px 8px #493b34; */
  }
  
  .contact_sec textarea:focus, input[type="text"]:focus, input[type="file"]:focus {
    outline: none;
    box-shadow: black;
    
  }
  /* Override the focused border color */
  .contact_sec .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black !important; /* Ensure this takes precedence */
  }
  
  /* Override the focused label color */
  .contact_sec .MuiInputLabel-root.Mui-focused {
    color: black !important;
  }
  
  .contact_sec button {
    background-color: #6b4e36;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    /* box-shadow: 4px 4px 8px #352e28, -4px -4px 8px #806c5c; */
    margin-top: 10px;
    transition: all 0.3s ease;
    /* font-family: 'Mate SC', serif; */
  }
  
  .contact_sec button:hover {
    /* background-color: #7d5e43; */
    box-shadow: 6px 6px 12px #352e28, -6px -6px 12px #8a755e;
  }