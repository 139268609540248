body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Plus Jakarta Sans', sans-serif; */
  background-color: "#F9F6E6";
}

/* If you want to apply 'Gowun Batang' to specific elements: */
/* h1, h2, h3, p.special-text {
  /* font-family: 'Gowun Batang', serif; */

