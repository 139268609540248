.header .top-strip {
    background-color: #020202; /* Softer background color */
    color: #f3f3f3; /* Text color for readability */
    padding: 10px 0px; /* Added padding on left and right */
    font-size: 15px; /* Standard text size */
    border-bottom: 1px solid #e0e0e0; /* Subtle separator */
}

.header .top-strip .container {
    display: flex;
    justify-content: space-between; /* Space out items evenly */
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    max-width: 1500px; /* Center content with max width */
    margin: 0 auto; /* Center-align container */
}

.header .contact-item {
    margin-right: 15px; /* Space between contact items */
    padding: 5px 0; /* Add vertical padding */
    white-space: nowrap; /* Prevent breaking in the middle of items */
}

.header .contact-item a {
    color: #fefefd; /* Standard link color */
    text-decoration: none;
    font-weight: 500; /* Slightly bold for emphasis */
}

.header .contact-item a:hover {
    text-decoration: underline; /* Subtle hover effect */
}
.header .address {
    display: flex;
    flex-wrap: wrap; /* Wrap items on small screens */
    gap: 5px; /* Add space between the parts */
    font-size: 14px;
  }
  
  .header .address span {
    display: inline-block;
    white-space: nowrap; /* Prevent wrapping inside each span */
  }
/* Responsive Design for smaller devices */
@media (max-width: 768px) {
    .header .top-strip .container {
        flex-direction: column; /* Stack items vertically */
        text-align: center; /* Center-align text */
    }

    .header .contact-item {
        margin-bottom: 10px; /* Space between stacked items */
        margin-right: 0; /* Reset right margin */
        align-items: center;
    }
    .header .address{
       display:none;
    }
}