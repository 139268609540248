/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Mate+SC&display=swap'); */
        
.admin-panel * {
  box-sizing: border-box;
}
.admin-panel .top_admin {
  display: flex;
  justify-content: space-between; /* Push items to opposite corners */
  align-items: center; /* Align items vertically (optional) */
  width: 100%; /* Ensure the container spans full width */
}
.admin-panel {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  /* font-family: 'Mate SC', serif; */
  color: #2e2b26;
}

h1 {
  /* font-family: 'Dancing Script', cursive; */
  font-size: 2.5rem;
  color: #f4ccac;
}

.section h2 {
  /* font-family: 'Mate SC', serif; */
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #d1a96b;
}

.section {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(145deg, #3f2e29, #2e2b26);
  box-shadow: 6px 6px 12px #25221e, -6px -6px 12px #493b34;
  color: #fff;
}

.about-us {
  background: linear-gradient(145deg, #3e332c, #2f2923);
}

.services {
  background: linear-gradient(145deg, #4a3b34, #382f2a);
}

.carousel {
  background: linear-gradient(145deg, #463c35, #312b26);
}

.contact {
  background: linear-gradient(145deg, #463c35, #312b26);
}

.metadata {
  background: linear-gradient(145deg, #463c35, #312b26);
}

.admin-panel textarea,
.admin-panel input[type="text"],
.admin-panel input[type="file"] {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: none;
  border-radius: 6px;
  background: #3d2f2a; 
  color: #d9c4a0;
  font-family: 'Mate SC', serif;
  box-shadow: inset 4px 4px 8px #29221d, inset -4px -4px 8px #493b34;
}

.admin-panel textarea:focus, .admin-panel input[type="text"]:focus, .admin-panel input[type="file"]:focus {
  outline: none;
  box-shadow: inset 4px 4px 12px #1f1c18, inset -4px -4px 12px #5a4b42;
}

.admin-panel, button {
  background-color: #6b4e36;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 4px 4px 8px #352e28, -4px -4px 8px #806c5c;
  margin-top: 10px;
  transition: all 0.3s ease;
  /* font-family: 'Mate SC', serif; */
}

.admin-panel, button:hover {
  background-color: #7d5e43;
  box-shadow: 6px 6px 12px #352e28, -6px -6px 12px #8a755e;
}

@media (max-width: 768px) {
  .admin-panel {
    margin-top: 150px;
  }
}